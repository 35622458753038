import React, { FunctionComponent } from 'react';

// Typescript doesn't recognize String.prototype.replaceAll?

const linkToSlack = (text: string): string =>
  text.replaceAll(/slack:#?([^ ]+)/g, '<a target="_blank" href="https://di-tylertech.slack.com/app_redirect?channel=$1">#$1</a>');
const linkToJira = (text: string): string =>
  text.replaceAll(/jira:?([A-Za-z]+-[0-9]+)/g, '<a target="_blank" href="https://socrata.atlassian.com/browse/$1">#$1</a>');
const markdownLink = (text: string): string =>
  text.replaceAll(/\[(.+?)\]\(([^ ]+)\)/g, '<a target="_blank" href="$2">#$1</a>');

const component: FunctionComponent<{ description?: string; }> = ({ description }) => {
  if (typeof description !== 'string') {
    return <div />;
  }

  const text = [
    linkToSlack,
    linkToJira,
    markdownLink,
  ].reduce((rawtext, autolinker) => autolinker(rawtext), description);

  return (<div
    className="contract-selector-right"
    dangerouslySetInnerHTML={{ __html: text }}
  />);
};

export default component;
